@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.feed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height to 100% of the viewport height */
  margin: 20px;
  padding: 20px;
}

.feed > h1 {
  font-family: "Pacifico", cursive;
  font-size: 48px;
  color: #4a90e2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: linear-gradient(45deg, #4a90e2, #a63297);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 100px;
}

/* Container for centering logo and text */
.login__logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-bottom: 100px;
}

.login__logo > img {
  width: 500px; /* Adjust the width as needed */
  height: auto;
}

.login__signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px -7px rgba(0, 0, 0, 0.75);
  padding: 50px;
}

.login__signin > h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(145deg, #801deb, #a44cf1);
  background: -webkit-linear-gradient(145deg, #801deb, #a44cf1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.login__button > button {
  margin-top: 40px;
  color: black;
  text-transform: none;
  border: 1px solid black;
  width: 400px;
  font-size: 18px;
  margin: 5px;
}

.login__button:hover > button {
  background-color: #edf1f5;
}

.login__error {
  margin: 20px;
  width: 500px;
}
